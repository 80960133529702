import { useEffect, useState } from 'react'
import ApiClient from 'api-client/ApiClient'
import { GetClusterKubeServicesItem } from 'api-client/qbert.model'

const GRAFANA_SERVICE_NAMESPACE = 'pf9-monitoring'
const GRAFANA_SERVICE_NAME_LABEL = 'app.kubernetes.io/name'
const GRAFANA_SERVICE_NAME = 'grafana'
const KUBERNETES_MANAGED_BY_LABEL = 'app.kubernetes.io/managed-by'
const HELM = 'Helm'
const GRAFANA_URL_LABEL = 'url'

const { qbert } = ApiClient.getInstance()

/**
 * @Summary Get all the services in `pf9-monitoring` namespace and find the grafana service deployed via HELM
 *          If url is available - use the link from service labels/annotation
 * @param existingGrafanaLink   // Proxy URL
 * @param clusterId
 * @returns newGrafanaLink
 */
const useGrafanaLink = (
  existingGrafanaLink: string,
  clusterId: string,
): { grafanaLink: string } => {
  const [grafanaLink, setGrafanaLink] = useState<string>(existingGrafanaLink)

  useEffect(() => {
    const getGrafanaLink = async () => {
      // Get list of all services in `pf9-monitoring` namespace
      const data: GetClusterKubeServicesItem[] = await qbert.getClusterKubeServices(
        clusterId,
        GRAFANA_SERVICE_NAMESPACE,
      )

      // Find the grafana service with name label as `grafana` AND managed label as `Helm`
      const grafanaService = data?.find(
        (item) =>
          item?.metadata?.labels?.[GRAFANA_SERVICE_NAME_LABEL] === GRAFANA_SERVICE_NAME &&
          item?.metadata?.labels?.[KUBERNETES_MANAGED_BY_LABEL] === HELM,
      )

      // Check if the URL is available in annotations or labels
      const grafanaURL =
        grafanaService?.metadata?.annotations?.[GRAFANA_URL_LABEL] ||
        grafanaService?.metadata?.labels?.[GRAFANA_URL_LABEL]
      if (grafanaURL) {
        setGrafanaLink(grafanaURL)
      }
    }

    getGrafanaLink()
  }, [])

  return { grafanaLink }
}

export default useGrafanaLink
